
import { Component } from 'vue-property-decorator';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';

@Component({
  components: { VFormBuilder },
})
export default class UserForm extends mixins(StackedForm) {
  public $refs!: {
    baseForm: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'firstName', type: InputType.Text, label: 'register.form.firstName', rules: 'required' },
      { name: 'lastName', type: InputType.Text, label: 'register.form.lastName', rules: 'required' },
      {
        name: 'email',
        type: InputType.Text,
        label: 'register.form.email',
        rules: 'required|email|unique_email',
      },
      { name: 'phone', type: InputType.Text, label: 'register.form.phone', rules: 'required' },
      {
        name: 'password',
        type: InputType.PasswordConfirmation,
        label: 'register.form.password',
        hint: 'register.form.passwordHint',
        rules: 'required|password|min:8',
      },
      {
        name: 'privacy',
        type: InputType.Checkbox,
        default: false,
        label: 'register.form.privacy',
        rules: { required: { allowFalse: false } },
      },
    ];
  }
}
