
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class VenueDialog extends Vue {
  public visible: boolean = false;

  public show() {
    this.visible = true;
  }
}
