
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import UserForm from '@/components/auth/UserForm.vue';
import AuthApiService from '@/api/http/AuthApiService';
import VenueDialog from '@/components/auth/VenueDialog.vue';
import { User } from '@/interfaces/models/User';
import { AxiosResponse } from 'axios';
import { CustomerGroup } from '@/enums/CustomerGroup';

@Component({
  components: { VenueDialog, UserForm, VWrapper },
})
export default class Register extends mixins(StackedForm, Notification) {
  public $refs!: {
    form: InstanceType<typeof UserForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
    dialog: InstanceType<typeof VenueDialog> & { show: () => void };
  };

  public user: User | null = null;

  public mounted(): void {
    if (!this.customerGroup) {
      this.$router.push({ name: 'start' });
    }
  }

  get logo() {
    return `https://storage.googleapis.com/smoothr-images-prod/logos/${this.customerGroup}.png`;
  }

  public register() {
    this.$refs.form.validate().then((res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        const api: AuthApiService = new AuthApiService();
        const data: Partial<User> & { firstName: string; lastName: string } = this.$refs.form.getData();
        api
          .registerFranchisee({
            ...data,
            name: `${data.firstName} ${data.lastName}`,
            linkedCustomerGroup: this.customerGroup!,
          })
          .then((res: AxiosResponse<User>) => {
            this.user = { ...this.$refs.form.getData(), ...res.data };
            this.$refs.dialog.show();
          });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  public toArray(enumme: any) {
    return Object.keys(enumme).map((key: string) => enumme[key]);
  }
  get customerGroup() {
    const tmpCg: string = this.$route.params.customerGroup;
    if (!tmpCg || tmpCg === '' || tmpCg === CustomerGroup.Subway) {
      return CustomerGroup.Subway;
    }
    if (tmpCg === 'kochloeffel') {
      return CustomerGroup.Kochloeffel;
    }

    const valueArray = this.toArray(CustomerGroup);
    if (valueArray.includes(tmpCg)) {
      return tmpCg;
    }

    return null;
  }

  public registerVenue() {
    // @ts-ignore
    this.$router.push({ name: 'register.venue', params: { user: this.user!, customerGroup: this.customerGroup } });
  }
}
